import React, { useEffect, useState, useRef } from 'react'
import '../App.css'
import Map from '../component/Map'
import cart from '../assets/image/cart.png'
import arrow from '../assets/image/arrow.png'
import alertImg from '../assets/image/alert.png'
import ScheduleBook from './ScheduleBook'
import AirportBook from './AirportBook'
import CharterBook from './CharterBook'
import SingaporeBook from './SingaporeBook'
import MalaysianCharterBook from './MalaysianCharterBook'
import Paynow from './Paynow'
import { useSelector } from "react-redux";
import store from '../redux/Store';
import moment from 'moment';

import { Autocomplete, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { ApiCall, ApiKey } from '../common/Apikey'

import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardModule from '../component/CardModule'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery'
import { ThreeDots } from 'react-loader-spinner'
import { useLocation } from 'react-router-dom';

function Home() {

    Geocode.setApiKey(ApiKey.google_map_key);
    Geocode.setLanguage("en");
    const [map_key, setMapKey] = useState(ApiKey.google_map_key)
    const [libraries, setLibraries] = useState(["places"])

    const [TripType, setTripType] = useState('schedule');
    const [DivShow, setDivShow] = useState(0);

    const [currencyData, setCurrency] = useState([])
    const [PayNowStatus, setPayNowStatus] = useState(0)

    const cartData = useSelector((state) => state.cart.AddCart);
    // console.log(cartData, '===>cartData')
    const [trip_data, setTripData] = useState('')

    // const VerifyData = useSelector((state) => state.verify.Verify);
    // const PersonData = useSelector((state) => state.saveData.Personal);

    const [main_name, setMainName] = useState("")
    const [email, setEmail] = useState("")
    const [re_email, setReEmail] = useState("")
    const [cc, setCc] = useState("+65")
    const [re_cc, setReCc] = useState("+65")
    const [phone, setPhone] = useState("")
    const [re_phone, setRePhone] = useState("")
    const [country_code, setCountryCode] = useState([]);
    const [verify_details, setVerifyDetails] = useState(0);
    const [verify_button, setVerifyBtn] = useState(0);
    const [formError, setFormError] = useState({ mainErr: "", emailErr: "", reemailErr: "", ccErr: "", phoneErr: "", reccErr: "", rephoneErr: "", behalfnameErr: "", behalfphoneErr: "", behalfccErr: "" })

    const [behalf_status, setBehalfStatus] = useState("0");
    const [behalf_name, setBehalfName] = useState("")
    const [behalf_phone_number, setBehalfPhoneNumber] = useState("")
    const [behalf_cc, setBehalfCc] = useState("+65")

    const location = useLocation();
    const [quotation_id,setQuotationId] = useState('');
    const modalTriggerRef = useRef(null);
    useEffect(()=>{
        const fetchData = async () =>{
            const urlData = new URLSearchParams(location.search);
            var data = urlData.get('id');
            if(data){
                store.dispatch({
                    type: "EmptyCart",
                    payload: {}
                });
                setQuotationId(data);
                let apiData = {
                    id:data
                }
                await ApiCall('GetQuotation', apiData).then((data) => {
                    if(data.message = 'success'){
                        if(data.result.expiry_status == 1){
                            toast.error('Your url is expired', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }else{
                            var data = JSON.parse(data.result.cart_data);
                            console.log(data);
                            data.forEach(item =>{
                                store.dispatch({
                                    type: "addToCart",
                                    payload: item
                                });
                            });
                        }
                        modalTriggerRef.current.click();
                    }
                });
                urlData.delete('id');
                const newUrl = `${location.pathname}${urlData.toString()}`;
                window.history.replaceState({}, '', newUrl);
            }
        }
        fetchData();
    },[location.search]);
    useEffect(() => {
        setTripData(cartData.length > 0 ? cartData : '')
        TotalAmount()
    }, [cartData])

    const [payment_type, setPaymentType] = useState("card");

    const [total_amount, setTotalAmount] = useState(0);

    const TotalAmount = () => {
        if (cartData.length > 0) {
            const totalAmount = cartData.reduce((sum, ride) => {
                return sum + parseFloat(ride.total_amount);
            }, 0);
            setTotalAmount(totalAmount)
        } else {
            const totalAmount = 0;
            setTotalAmount(totalAmount)
        }
    }

    const stripePromise = loadStripe(ApiKey.stripekey);

    const TripChange = (event) => {
        let { name, value } = event.target;
        setTripType(value)
    }

    const paySelect = (event) => {
        let { name, value } = event.target;
        setPaymentType(value)
    }

    const showDiv = () => {
        let status = DivShow;
        if (status == 0) {
            setDivShow(1);
        } else {
            setDivShow(0);
        }
    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    async function clearCart(event) {
        event.preventDefault();
        setTripData('')
        store.dispatch({
            type: "EmptyCart",
            payload: {}
        });
    }

    const getSuccess = async (token) => {
        const tokenMessage = await token;
        if (tokenMessage == 'sucess') {
            toast.success('Payment done Please Wait for confirm trip..', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            if (payment_type == 'card') {
                let data = {
                    booking_data: JSON.stringify(cartData),
                    payment_name: payment_type,
                    passenger_name: main_name,
                    passenger_phone_code: cc,
                    passenger_phone_number: cc + '' + phone,
                    passenger_email: email,
                    behalf_status: behalf_status,
                    behalf_name: behalf_name,
                    behalf_phone_number: behalf_cc + '' + behalf_phone_number,
                    quotation_id : quotation_id,
                }
                await ApiCall('booking/guest_add', data).then((data) => {
                    if (data.message == "success") {
                        BookTripsMessage();
                    } else {
                        toast.error(data.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                BookTripsMessage();
            }

        } else {
            toast.error('Payment Failed, Please Try Again....', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setTimeout(() => { refreshPage() }, 5000)
        }
    }

    const BookTripsMessage = () => {
        toast.success('Booking Added Successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        setTripData('')
        store.dispatch({
            type: "EmptyCart",
            payload: {}
        });
        store.dispatch({
            type: "EmptyVerify",
            payload: { verify_status: 0 }
        });
        store.dispatch({
            type: "RemoveDetails",
            payload: {}
        });
        setTimeout(() => { refreshPage() }, 5000)
    }

    async function CountryCodeApi() {
        let data = {}
        await ApiCall('countrycode', data).then((data) => {
            if (data.success === "success") {
                setCountryCode(data.data)
            }
        }).catch(err => console.log(err));
    }

    const inputChange = (event) => {
        let { name, value } = event.target;
        if (name == 'email') {
            setEmail(value)
        } else if (name == 'main_name') {
            setMainName(value)
        } else if (name == 'cc') {
            setCc(value)
        } else if (name == 'phone') {
            setPhone(value)
        } else if (name == 'reemail') {
            setReEmail(value)
        } else if (name == 'recc') {
            setReCc(value)
        } else if (name == 'behalf_name') {
            setBehalfName(value)
        } else if (name == 'behalf_phone') {
            setBehalfPhoneNumber(value)
        } else if (name == 'behalf_cc') {
            setBehalfCc(value)
        } else if (name == 'behalf_status') {
            setBehalfStatus(behalf_status == 1 ? 0 : 1)
        } else {
            setRePhone(value)
        }
    }

    const FormValidation = () => {
        let isValid = true;
        setFormError({})
        if (main_name.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'mainErr': 'Enter Name' }))
        }
        if (email.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'emailErr': 'Enter Email' }))
        }
        if (re_email.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'reemailErr': 'Enter Email' }))
        }
        if (cc == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'ccErr': 'Enter CC' }))
        }
        if (re_cc == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'reccErr': 'Enter CC' }))
        }
        if (behalf_status == 1 && behalf_cc == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'behalfccErr': 'Enter CC' }))
        }
        if (phone.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'phoneErr': 'Enter Phone Number' }))
        }
        if (re_phone.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rephoneErr': 'Enter Phone Number' }))
        }
        if (behalf_status == 1 && behalf_name.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'behalfnameErr': 'Enter Name' }))
        }
        if (behalf_status == 1 && behalf_phone_number.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'behalfphoneErr': 'Enter Phone Number' }))
        }

        if (email !== "") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2)) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'emailErr': 'Enter Valid Email' }))
            }
        }

        if (re_email !== "") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2)) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'reemailErr': 'Enter Valid Email' }))
            }
        }

        if (phone !== "") {
            let number_length = phone.length;
            if (number_length < 8) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'phoneErr': 'Enter Valid Phone' }))
            }
            if (number_length > 12) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'phoneErr': 'Enter Valid Phone' }))
            }
        }

        if (re_phone !== "") {
            let number_length = phone.length;
            if (number_length < 8) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'rephoneErr': 'Enter Valid Phone' }))
            }
            if (number_length > 12) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'rephoneErr': 'Enter Valid Phone' }))
            }
        }

        if (behalf_status == 1 && behalf_phone_number !== "") {
            let number_length = phone.length;
            if (number_length < 8) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'behalfphoneErr': 'Enter Valid Phone' }))
            }
            if (number_length > 12) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'behalfphoneErr': 'Enter Valid Phone' }))
            }
        }

        if (email != re_email) {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'reemailErr': 'Email Mis-Match' }))
        }

        if (phone != re_phone) {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rephoneErr': 'Phone Number Mis-Match' }))
        }

        if (cc != re_cc) {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'reccErr': 'CC Mis-Match' }))
        }

        return isValid;
    }

    function timeStringToMilliseconds(timeString) {
        const [hours, minutes, period] = timeString.split(/:| /);
        let totalMilliseconds = parseInt(hours, 10) * 60 * 60 * 1000;
        totalMilliseconds += parseInt(minutes, 10) * 60 * 1000;
        if (period.toLowerCase() === "pm") {
            totalMilliseconds += 12 * 60 * 60 * 1000;
        }
        return totalMilliseconds;
    }

    const VerifyBtn = (event) => {
        event.preventDefault()
        setVerifyBtn(1)
        if (FormValidation()) {
            var currentDate = new Date();
            currentDate.setUTCHours(currentDate.getUTCHours() + 8);
            var year = currentDate.getUTCFullYear();
            var month = ('0' + (currentDate.getUTCMonth() + 1)).slice(-2);
            var day = ('0' + currentDate.getUTCDate()).slice(-2);
            var hours = ('0' + currentDate.getUTCHours()).slice(-2);
            var minutes = ('0' + currentDate.getUTCMinutes()).slice(-2);
            var amPM = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12;
            var currentDate = year + '-' + month + '-' + day;
            var currentTime = hours + ':' + minutes + ' ' + amPM;
            cartData.forEach(booking => {
                // var pick_up_time = timeStringToMilliseconds(booking.pick_up_time);
                // var c_Time = timeStringToMilliseconds(currentTime);
                // var pick_up_DateTime = booking.ride_date + ' ' + booking.pick_up_time;
                // var current_DateTime = currentDate + ' ' + currentTime;
                var pick_up_DateTime = new Date(booking.ride_date + ' ' + booking.pick_up_time);
                var current_DateTime = new Date(currentDate + ' ' + currentTime);
                // if (booking.ride_date <= currentDate && pick_up_time <= c_Time) {
                if (pick_up_DateTime <= current_DateTime) {
                    setVerifyBtn(0)
                    toast.error("Booking time should be greater than current time", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => { refreshPage() }, 5000)
                }
            });
            setVerifyDetails(1)
            // store.dispatch({
            //     type: "VerifyDetails",
            //     payload: {verify_status: 1}
            // });
            // store.dispatch({
            //     type: "StoreDetails",
            //     payload: {
            //         passenger_name: main_name,
            //         passenger_phone_code: cc,
            //         passenger_phone_number: cc + '' + phone,
            //         passenger_email: email,
            //     }
            // });
            // setTimeout(() => {
            //     $('#VDclose').click()
            //     $('#OpenCart').click()
            // }, 3000);
        } else {
            setVerifyBtn(0)
        }
    }

    const Appsetting = async () => {
        let data = {}
        await ApiCall('GuestAppSetting', data).then((data) => {
            if (data.success == "success") {
                setCurrency(data.currency)
                setPayNowStatus(data.guest_paynow_status)
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        TotalAmount()
        CountryCodeApi()
        Appsetting()
        localStorage.removeItem('stopPosition0')
        localStorage.removeItem('stopPosition1')
        localStorage.removeItem('pick_up')
        localStorage.removeItem('pickupPosition')
        localStorage.removeItem('drop')
        localStorage.removeItem('dropoffPosition')
    }, [])

    const RemoveSingle = async (index) => {
        store.dispatch({
            type: "RemoveCart",
            payload: {
                id: index
            }
        });
    }

    useEffect(() => {
        const getBgColorFromURL = () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const bgcolorParam = urlSearchParams.get('color');
            return bgcolorParam || '#ca2733';
        };

        const bgcolorFromURL = getBgColorFromURL();
        if (bgcolorFromURL != '#ca2733' && bgcolorFromURL != 'ffffff') {
            const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
            if (hexColorRegex.test('#' + bgcolorFromURL)) {
                document.documentElement.style.setProperty('--bgcolor', '#' + bgcolorFromURL);
            }
        }
    }, []);

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <LoadScript libraries={libraries} googleMapsApiKey={ApiKey.google_map_key}>
                <div>
                    {/* <Map /> */}

                    {/* big Div */}
                    {/* <div className={DivShow == 0 ? 'd-flex flex-column justify-content-between' : 'd-flex flex-column justify-content-between d-none'}>
                        <div className='p-4 row container order-2 rawDiv'>
                            <div className="col-lg-5 col-12 divBg">
                                <div className="row">
                                    <div className='col-lg-8 col-6'>
                                        <h5>NEW BOOKING</h5>
                                    </div>
                                    <div className='col-lg-4 col-6 text-end'>
                                        <i className="fa-solid fa-rotate-right reloadIcon" onClick={refreshPage}></i>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className=''>
                                    <p>Booking Type</p>
                                    <div className='row'>
                                        <div className='col-lg-2 col-12 me-3 p-0'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="schedule" onClick={TripChange} defaultChecked={TripType == 'schedule' ? true : false} />
                                                <label className="form-check-label">
                                                    Scheduled
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-12 m-0 p-0'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="airport" onClick={TripChange} defaultChecked={TripType == 'airport' ? true : false} />
                                                <label className="form-check-label">
                                                    Airport Pick up
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-12 m-0 p-0'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="charter" onClick={TripChange} defaultChecked={TripType == 'charter' ? true : false} />
                                                <label className="form-check-label">
                                                    Charter
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12 m-0 p-0'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="singapore" onClick={TripChange} defaultChecked={TripType == 'singapore' ? true : false} />
                                                <label className="form-check-label">
                                                    Singapore/Malaysia
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {TripType == 'schedule' ? <ScheduleBook /> : TripType == 'airport' ? <AirportBook /> : TripType == 'charter' ? <CharterBook /> : <SingaporeBook />}
                                </div>
                            </div>
                        </div>
                        <div className="text-end p-2" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false">
                            <img src={cart} alt="cart" className="carIcon order-1" />
                            <span className='badge badge-warning' id='lblCartCount'> {trip_data.length} </span>
                        </div>
                    </div> */}
                    {/* small div */}
                    {/* <div className={DivShow == 0 ? 'd-flex flex-column justify-content-between d-none' : 'd-flex flex-column justify-content-between'}>
                        <div className='p-4 row container order-2 rawDiv'>
                            <div className="col-lg-5 col-12 hideDiv">
                                <div className="row">
                                    <div className='col-lg-8 col-6'>
                                        <h4>NEW BOOKING</h4>
                                    </div>
                                    <div className='col-lg-4 col-6 text-end'>
                                        <i className="fa-solid fa-rotate-right reloadIcon" onClick={refreshPage}></i>
                                    </div>
                                </div>
                                <hr></hr>
                                <p>Booking Type : {TripType.charAt(0).toUpperCase() + TripType.slice(1)}</p>
                                <p>Pickup Location : {localStorage.getItem('pick_up')}</p>
                                <p>Drop Location : {localStorage.getItem('drop')}</p>
                            </div>
                        </div>
                        <div className="text-end p-2" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false">
                            <img src={cart} alt="cart" className="carIcon order-1" />
                            <span className='badge badge-warning' id='lblCartCount'> {trip_data.length} </span>
                        </div>
                    </div> */}

                    {/* <div className="p-2 arrowIcon">
                        <img src={arrow} alt="arrow" className="carIcon order-1" onClick={showDiv} />
                    </div> */}


                    {/* new design */}
                    <div className="new_div">
                        <div className='p-4 row container-fluid new-container'>
                            <div className="col-12 divBg-new">
                                <div className="row">
                                    <div className='col-lg-8 col-6'>
                                        <h5>NEW BOOKING</h5>
                                    </div>
                                    <div className='col-lg-4 col-6 text-end'>
                                        <i className="fa-solid fa-rotate-right reloadIcon" onClick={refreshPage}></i>
                                    </div>
                                </div>
                                {/* <hr></hr> */}
                                <div className=''>
                                    <div className='row mb-3'>
                                        <div className='col-lg-2 col-12'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="schedule" onClick={TripChange} defaultChecked={TripType == 'schedule' ? true : false} />
                                                <label className="form-check-label">
                                                    Point-to-Point
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-12'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="airport" onClick={TripChange} defaultChecked={TripType == 'airport' ? true : false} />
                                                <label className="form-check-label">
                                                    Airport Pickup
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-12'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="charter" onClick={TripChange} defaultChecked={TripType == 'charter' ? true : false} />
                                                <label className="form-check-label">
                                                    Charter
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-12'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="singapore" onClick={TripChange} defaultChecked={TripType == 'singapore' ? true : false} />
                                                <label className="form-check-label">
                                                    Singapore/Malaysia
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-12'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="trip_type" id="trip_type" value="malaysian_charter" onClick={TripChange} defaultChecked={TripType == 'malaysian_charter' ? true : false} />
                                                <label className="form-check-label">
                                                    Malaysian Charter
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {TripType == 'schedule' ? <ScheduleBook /> : TripType == 'airport' ? <AirportBook /> : TripType == 'charter' ? <CharterBook /> : TripType == 'singapore' ? <SingaporeBook /> : <MalaysianCharterBook />}
                                </div>
                            </div>
                        </div>
                        <div className="p-2 new-cart" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false" ref={modalTriggerRef} >
                            {/* <img src={cart} alt="cart" className="carIcon order-1" /> */}
                            <div className='iconDiv'>
                                <i className="fa-solid fa-bag-shopping carIcon order-1"></i>
                                <span className='badge badge-warning' id='lblCartCount'> {trip_data.length} </span>
                            </div>
                        </div>
                    </div>

                    {/* Modal */}
                    <div className='d-none'>
                        <input data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false" id="OpenCart" />
                    </div>
                    <div className="modal left" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="false">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='d-flex justify-content-between align-items-center w-25'>
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Cart</h1>
                                        <i className='fa fa-trash align-items-center' onClick={clearCart}></i>
                                    </div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="modelClose"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='modelHeight'>
                                        <div className="accordion" id="accordionExample">
                                            {trip_data != '' ? trip_data.map((data, i) => {
                                                return (
                                                    <div className="accordion-item" key={i}>
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + i} aria-expanded="false" aria-controls={'collapse' + i}>
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className='col-6'>
                                                                        <p className='txtColor'>Booking Type : {data.type}</p>
                                                                        <p className='txtColor'>Trip Amount: $ {data.total_amount}</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        {data.type == "Airport" ? <p className='txtColor'>Date/ Time : {moment(data.flight_actual_arrival_time).format('YYYY-MM-DD hh:mm a')}</p> : <p className='txtColor'>Date/ Time : {data.date} {data.pick_up_time}</p>}
                                                                        <i className='fa fa-trash textColor' onClick={() => RemoveSingle(i)}></i>
                                                                    </div>

                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={'collapse' + i} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <table className='table align-middle txtColor'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><b>Pickup Location</b></td>
                                                                            <td><b>:</b></td>
                                                                            <td>{data.pick_up}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><b>Drop Location</b></td>
                                                                            <td><b>:</b></td>
                                                                            <td>{data.drop_location}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><b>Vehicle Type</b></td>
                                                                            <td><b>:</b></td>
                                                                            <td>{data.vehicle_typeName.split('_').join(" ")}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><b>Trip Amount</b></td>
                                                                            <td><b>:</b></td>
                                                                            <td>{data.total_amount} {currencyData.symbol}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><b>Add-on Services</b></td>
                                                                            <td><b>:</b></td>
                                                                            <td>
                                                                                {data.addon_data.length > 0 ? data.addon_data.map((addon_data, key) => {
                                                                                    return (addon_data.name + '-' + addon_data.count + ' ')
                                                                                }) : <>-</>}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <p className='emptyCart'>Your Cart is Empty</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className='row'>
                                        <div className="col-6">
                                            <span>No of Booking ({trip_data.length})</span><br />
                                            <span>Total Price : {parseFloat(total_amount).toFixed(2)} {currencyData.symbol}</span>
                                            {/* <br />
                                            <span>Payment Mode : </span>
                                            <select className='paySelect' name="paySelect" onClick={paySelect}>
                                                <option defaultValue='card' value="card">Card</option>
                                                <option value='paynow'>Pay Now</option>
                                            </select> */}
                                        </div>

                                        {/* {verify_details == 1 ? <>{payment_type == 'card' ? <div className='col-6 d-flex align-items-center justify-content-end'>
                                            <button type="submit" className="btn rounded-pill cartBtn" data-bs-toggle="modal" data-bs-target="#CardModule">Confirm</button>
                                        </div> : <div className='col-6 d-flex align-items-center justify-content-end'>
                                            <Elements stripe={stripePromise}>
                                                <Paynow amount={total_amount} getSuccess={getSuccess} />
                                            </Elements>
                                        </div>}</> : <div className='col-6 d-flex align-items-center justify-content-end'>
                                            <input type="button" className="btn rounded-pill cartBtn" data-bs-toggle="modal" data-bs-target="#VerifyDetail" Value="Verify" disabled={trip_data.length == 0 ? true : false} />
                                        </div>} */}

                                        <div className='col-6 d-flex align-items-center justify-content-end'>
                                            <input type="button" className="btn rounded-pill cartBtn" data-bs-toggle="modal" data-bs-target="#VerifyDetail" Value="Verify" disabled={trip_data.length == 0 ? true : false} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Elements stripe={stripePromise}>
                        <CardModule id="CardModule" title="Confirm Your Payment" amount={total_amount} email={email} phoneNumber={cc + '' + phone} getSuccess={getSuccess} />
                    </Elements>
                </div>
            </LoadScript>

            {/* Verify Details Model */}
            <div className="modal fade" id="VerifyDetail" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="VerifyPhoneLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header add-modal">
                            <h5 className="modal-title" id="staticBackdropLabel">Verify Passenger Details</h5>
                            <i className="fa fa-window-close" data-bs-dismiss="modal" aria-label="Close" id="VDclose"></i>
                        </div>
                        <form method="post" autoComplete="off">
                            <div className="modal-body geo-body">


                                <div className="mb-1">
                                    <label className="form-label verifyLabel">Your Name</label>
                                    <input type="text" className="form-control rounded-pill" placeholder='Passenger Name' name='main_name' id="main_name" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.mainErr}</span>
                                </div>


                                <div className='mb-1'>
                                    <label className="form-label verifyLabel">Your Email</label>
                                    <input type="text" className="form-control rounded-pill" placeholder='Passenger Email' name='email' id="email" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.emailErr}</span>
                                </div>

                                <div className='row mb-1'>
                                    <label className="form-label verifyLabel">Your Phone Number</label>
                                    <div className='col-lg-4 col-12'>
                                        <select className="form-select rounded-pill" aria-label="Default select example" name='cc' id="cc" onChange={inputChange} disabled={verify_button == 1 ? true : false}>
                                            {country_code.map((data, i) => {
                                                return <option key={i} value={'+' + data.phonecode} selected={data.phonecode == 65 ? true : false}>+{data.phonecode}-{data.sortname}</option>
                                            })}
                                        </select>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.ccErr}</span>

                                    </div>
                                    <div className='col-lg-8 col-12'>
                                        <div className="mb-1">
                                            <input type="text" className="form-control rounded-pill" placeholder='Phone' name='phone' id="phone" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                        </div>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.phoneErr}</span>
                                    </div>
                                </div>

                                <hr className='verifyLabel' />
                                <h5 className='verifyLabel'>Reconfirm the identity</h5>

                                <div className='mb-1'>
                                    <label className="form-label verifyLabel">Your Email</label>
                                    <input type="text" className="form-control rounded-pill" placeholder='Passenger Email' name='reemail' id="reemail" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.reemailErr}</span>
                                </div>

                                <div className='row mb-1'>
                                    <label className="form-label verifyLabel">Your Phone Number</label>
                                    <div className='col-lg-4 col-12'>
                                        <select className="form-select rounded-pill" aria-label="Default select example" name='recc' id="recc" onChange={inputChange} disabled={verify_button == 1 ? true : false}>
                                            {country_code.map((data, i) => {
                                                return <option key={i} value={'+' + data.phonecode} selected={data.phonecode == 65 ? true : false}>+{data.phonecode}-{data.sortname}</option>
                                            })}
                                        </select>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.reccErr}</span>
                                    </div>
                                    <div className='col-lg-8 col-12'>
                                        <div className="mb-1">
                                            <input type="text" className="form-control rounded-pill" placeholder='Phone' name='rephone' id="rephone" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                        </div>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rephoneErr}</span>
                                    </div>
                                </div>

                                <hr className='verifyLabel' />
                                <div class="form-check mb-1">
                                    <input class="form-check-input" type="checkbox" value="1" name='behalf_status' id="behalf_status" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                    <label class="form-check-label verifyLabel" for="behalf_status">Booking on behalf</label>
                                </div>
                                <div className={behalf_status == 1 ? '' : 'd-none'}>
                                    <div className='mb-1'>
                                        <label className="form-label verifyLabel">Passenger Name</label>
                                        <input type="text" className="form-control rounded-pill" placeholder='Name' name='behalf_name' id="behalf_name" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.behalfnameErr}</span>
                                    </div>
                                    <div className='row mb-1'>
                                        <label className="form-label verifyLabel">Passenger Phone Number</label>
                                        <div className='col-lg-4 col-12'>
                                            <select className="form-select rounded-pill" aria-label="Default select example" name='behalf_cc' id="behalf_cc" onChange={inputChange} disabled={verify_button == 1 ? true : false}>
                                                {country_code.map((data, i) => {
                                                    return <option key={i} value={'+' + data.phonecode} selected={data.phonecode == 65 ? true : false}>+{data.phonecode}-{data.sortname}</option>
                                                })}
                                            </select>
                                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.behalfccErr}</span>
                                        </div>
                                        <div className='col-lg-8 col-12'>
                                            <div className="mb-1">
                                                <input type="text" className="form-control rounded-pill" placeholder='Phone' name='behalf_phone' id="behalf_phone" onChange={inputChange} disabled={verify_button == 1 ? true : false} />
                                            </div>
                                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.behalfphoneErr}</span>
                                        </div>
                                    </div>
                                </div>

                                <hr className='verifyLabel' />
                                <h5 className='verifyLabel'>Payment Mode</h5>
                                <div>
                                    {/* <select className='form-select paySelect rounded-pill' name="paySelect" onClick={paySelect}>
                                        <option defaultValue='card' value="card">Card</option>
                                        <option value='paynow'>Pay Now</option>
                                    </select> */}

                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="paySelect" id="paySelect" value="card" onClick={paySelect} defaultChecked={payment_type == 'card' ? true : false} />
                                            <label className="form-check-label verifyLabel">Card</label>
                                        </div>
                                        {PayNowStatus == 1 ?
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="paySelect" id="paySelect" value="paynow" onClick={paySelect} defaultChecked={payment_type == 'paynow' ? true : false} />
                                                <label className="form-check-label verifyLabel">Paynow</label>
                                            </div> : <></>
                                        }
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer geo-body">
                                <div className="text-end">
                                    {verify_button == 1 ? <>
                                        {payment_type == 'card' ? <div className='col-12 d-flex align-items-center justify-content-center'>
                                            <button type="button" className="btn rounded-pill VerBtn" data-bs-toggle="modal" data-bs-target="#CardModule">Confirm</button>
                                        </div> : <div className='col-12 d-flex align-items-center justify-content-center'>
                                            <Elements stripe={stripePromise}>
                                                <Paynow amount={total_amount} email={email} phoneNumber={cc + '' + phone} paymentName={payment_type} passengerName={main_name} passengerCode={cc} behalfStatus={behalf_status} behalfName={behalf_name} behalfPhoneNumber={behalf_cc + '' + behalf_phone_number} getSuccess={getSuccess} />
                                            </Elements>
                                        </div>}</> : <div className='d-flex justify-content-center'>
                                        <input type="button" className="btn rounded-pill VerBtn" Value="Verify" onClick={VerifyBtn} />
                                    </div>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <>
                {/* model for confirmation */}
                <div className='d-none'>
                    <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#confirmMessage" data-bs-backdrop="false" id="messageConfirm" />
                </div>
                <div class="modal fade" id="confirmMessage" aria-labelledby="confirmMessage" aria-hidden="true" data-bs-backdrop="false">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header megHeader">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Vimo Services</h1>
                                <button type="button" class="btn-close d-none" data-bs-dismiss="modal" id="confirmClose" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='container'>
                                    <div className='d-flex align-item-center justify-content-center'>
                                        <img className="" src={alertImg} width={250} height={250} />
                                    </div>
                                    <h5 className='alertText text-center p-2'>DO NOT GO AWAY from this screen and do not close the window. It may takes up to 1 minute for payment confirmation. And you shall receive sms and email as confirmation</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default Home